import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import NotFound from '@/views/NotFound.vue';

const redirectPaths = [
  'agency',
  'services',
  'platforms',
  'contacts',
  'action',
  'faq'
];

const routes = [
  ...redirectPaths.map(path => ({
    path: `/${path}`,
    redirect: '/'
  })),
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: (to, from, next) => {
      console.log('Navigating to:', to.fullPath);
      const allowedParams = ['scrollTo'];
      const queryKeys = Object.keys(to.query);
      const hasDisallowedParams = queryKeys.length > 0 &&
        queryKeys.some(key => !allowedParams.includes(key));
      if (hasDisallowedParams) {
        next({ path: '/', query: {} });
      } else {
        next();
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;